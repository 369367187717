<template>
  <div class="footer-wrap gray-bcg-color">
    <div class="footer">
      <div class="c-width">
        <div class="footer_l">
          <div class="title">
            <i class="iconfont iconweihouyuntubiao"></i>
            <span>关于微吼云</span>
          </div>
          <p>
            微吼云隶属于北京微吼时代科技有限公司，是微吼旗下的视频云业务。微吼云基于微吼多年视频服务技术积累，为广大开发者提供行业领先的直播、点播、转码、智能
            CDN开发、大规模实时消息服务等能力，为客户提供专业的商业直播技术服务。
          </p>
          <div class="title">
            <i class="iconfont iconlianxifangshi"></i>
            <span>联系方式</span>
          </div>
          <p>
            北京 · 硅谷 · 上海 · 广州 · 深圳<br />北京 ·
            朝阳区建国路27号紫檀大厦3层
          </p>
          <div>
            <i class="iconfont icondianhua"></i>400-888-9970
            <i class="iconfont email iconyoujian"></i>market@vhall.com
          </div>
          <div class="nav-list">
            友情链接：
            <a href="https://www.vhall.com/" target="_blank">微吼官网</a>
            <a href="https://zhike.vhall.com/" target="_blank">微吼知客</a>
            <a href="https://e.vhall.com/" target="_blank">微吼直播</a>
            <a href="https://class.vhall.com/" target="_blank">微吼课堂</a>
            <a :href="`${domain}/client-download`" target="_blank">微吼·云会议</a>
          </div>
        </div>
        <div class="footer_r">
          <el-image
            class="qrcode"
            :src="require('../../../assets/images/qrcode.png')"
          ></el-image>
          <p>微信公众号</p>
        </div>
      </div>
    </div>
    <copy-right></copy-right>
  </div>
</template>

<script>
import copyRight from './copyright'
export default {
  components: { copyRight },
  data() {
    return {
      domain: `//${window.location.host}`
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../assets/less/varible.less';
.footer-wrap {
  color: #7b89a0;
  .flex {
    display: flex;
    justify-content: space-between;
  }
  .footer {
    padding: 50px 0 12px;
    font-size: 14px;
    border-bottom: 1px solid #eaecf3;
    .title {
      margin-bottom: 10px;
      color: #6c798e;
      font-weight: 500;
      font-size: 16px;
      .iconweihouyuntubiao {
        font-size: 21px;
        margin-right: 5px;
      }
      .iconlianxifangshi {
        font-size: 15px;
        margin-right: 10px;
      }
    }
    .icondianhua,
    .iconyoujian {
      font-size: 12px;
      margin-right: 5px;
    }
    p {
      margin-bottom: 10px;
      line-height: 20px;
    }
    .c-width {
      .flex;
      .email {
        margin-left: 50px;
      }
      .footer_l {
        margin-right: 65px;
        .nav-list {
          margin-top: 20px;
          display: flex;
          justify-content: flex-start;
          align-items: baseline;
          a {
            font-size: 14px;
            padding: 0 15px;
            line-height: 12px;
            cursor: pointer;
            &:first-child {
              padding-left: 0;
            }
            &:not(:first-child) {
              border-left: 1px solid #7b89a0;
            }
            &:hover {
              color: @mainColor;
            }
          }
        }
      }
      .footer_r {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 65px;
        border-left: 1px solid #eaecf3;
        p {
          margin-top: 5px;
          color: #7b89a0;
          white-space: nowrap;
          text-align: center;
        }
        .qrcode {
          width: 135px;
        }
      }
    }
  }
}
</style>
