<template>
  <div class="remark" :class="{ 'c-width': !isDocs }">
    <span>Copyright © {{new Date().getFullYear()}} Vhall Inc. All Rights Reserved.</span>
    <span class="jinghui"
      ><i class="iconfont iconjinghui"></i>京公网安备 11010502033647号</span
    >
    <a class="icp" target="_blank" href="//beian.miit.gov.cn"
      >京ICP备13004264号-7</a
    >
    <span>微吼 | 微吼云</span>
  </div>
</template>

<script>
export default {
  props: {
    isDocs: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../assets/less/varible.less';
.remark {
  font-size: 12px;
  line-height: 60px;
  display: flex;
  justify-content: space-between;
  .jinghui {
    display: flex;
    align-items: center;
    .iconjinghui {
      font-size: 16px;
      margin-right: 5px;
    }
  }
  .icp {
    cursor: pointer;
    &:hover {
      color: @mainColor;
    }
  }
}
</style>
