<template>
  <section class="download-client">
    <header class="header">
      <img src="./img/logo-red.png" width="62" alt="" />
      <p class="header__title">微吼·云会议</p>
    </header>
    <div class="content">
      <h4 class="content__title">免费下载</h4>
      <div class="client-list">
        <div>
          <div class="client__logo" @click="downloadClient('mac')">
            <img src="./img/macOS.png" width="62" alt="" />
          </div>
          <p class="client__desc">macOS版</p>
          <div class="client-text__box">
            <span class="text__macOS">已支持M1机型</span>
          </div>
        </div>
        <div>
          <div class="client__logo" @click="downloadClient('windows')">
            <img src="./img/windows.png" width="62" alt="" />
          </div>
          <p class="client__desc">Windows版</p>
        </div>
        <div>
          <div class="client__logo platform__ios" @click="toStore('iphone')">
          </div>
          <p class="client__desc">iPhone版</p>
        </div>
        <div>
          <div class="client__logo platform__android" @click="toStore('android')">
          </div>
          <p class="client__desc">Android版</p>
        </div>
      </div>
      <a class="link_btn" :href="`${domain}/vmeeting`" target="_blank">快速开始会议</a>
    </div>
    <c-footer></c-footer>
  </section>
</template>

<script>
import cFooter from '../layout/components/footer'
export default {
  components: { cFooter },
  data() {
    return {
      domain: `//${window.location.host}`
    }
  },
  methods: {
    downloadClient(type) {
      const types = {
        mac: `https://${process.env.VUE_APP_SDK_PATH}vhallyun-app-download/Vmeeting.pkg`,
        windows: `https://${process.env.VUE_APP_SDK_PATH}vhallyun-app-download/Vmeeting.exe`
      }
      location.href = types[type]
    },
    toStore(type) {
      const types = {
        iphone: 'https://itunes.apple.com/cn/app/id1119902970',
        android: 'https://www.pgyer.com/Vmeeting'
      }
      window.open(types[type], '_blank')
    }
  }
}
</script>

<style lang="less" scoped>
.download-client {
  width: 1200px;
  margin: auto;
  .header {
    display: flex;
    align-items: center;
    &__title {
      font-size: 29px;
      font-weight: bold;
      color: #333;
      margin-left: 20px;
    }
  }
  .content__title {
    font-size: 33px;
    font-weight: bold;
    color: #333;
    text-align: center;
  }
  .client-list {
    display: flex;
    justify-content: space-between;
    align-items: top;
    margin: 40px 0 35px;
  }
  .client__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 150px;
    background: #f7f7f7;
    border-radius: 50%;
    cursor: pointer;
  }
  .client__logo.platform__ios {
    background-image: url('./img/ios.png'), url('./img/logo-section.png');
    background-repeat: no-repeat, no-repeat;
    background-size:62px, 150px;
    background-position:center, bottom;
    &:hover {
      background-image: url('./img/ios-qrcode.png');
      background-size:100px;
    }
  }
  .client__logo.platform__android {
    background-image: url("./img/android.png"), url('./img/logo-section.png');
    background-repeat: no-repeat, no-repeat;
    background-size:62px, 150px;
    background-position:center, bottom;
    &:hover {
      background-image: url('./img/android-qrcode.png');
      background-size:100px;
    }
  }
  .client__desc {
    margin-top: 16px;
    font-size: 16px;
    color: #333;
    text-align: center;
  }
  .client-text__box {
    margin-top: 10px;
    width: 110px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px auto;
    background: #F1F5FF;
    border-radius: 4px;
    color: #82A7FC;
    font-size: 12px;
  }
  .link_btn {
    width: 220px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FC5659;
    border-radius: 33px;
    margin: 0 auto 60px;
    color: #FFFFFF;
    font-size: 14px;
    font-family: arial;
  }
}
</style>
